import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "all-events" }
const _hoisted_2 = {
  key: 0,
  class: "all-events_event"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event = _resolveComponent("event")!
  const _component_events = _resolveComponent("events", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_events, { onLoaded: _ctx.onLoaded }, {
      events: _withCtx(({ events }) => [
        (events.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events, (event) => {
                return (_openBlock(), _createBlock(_component_event, {
                  key: event.id,
                  event: event,
                  class: "all-events_event"
                }, null, 8, ["event"]))
              }), 128)),
              (_ctx.addBlock(events.length))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true)
            ], 64))
          : _renderSlot(_ctx.$slots, "empty", { key: 1 })
      ]),
      _: 3
    }, 8, ["onLoaded"])
  ]))
}