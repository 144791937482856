import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "all-catalog" }
const _hoisted_2 = { class: "all-catalog_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item = _resolveComponent("item")!
  const _component_items = _resolveComponent("items")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('courseCatalog')), 1),
    _createVNode(_component_items, {
      onLoaded: _ctx.onLoaded,
      class: "all-catalog_items"
    }, {
      items: _withCtx(({ items }) => [
        (items.length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(items, (item) => {
              return (_openBlock(), _createBlock(_component_item, {
                key: item.id,
                item: item,
                class: "all-catalog_item"
              }, null, 8, ["item"]))
            }), 128))
          : _renderSlot(_ctx.$slots, "empty", { key: 1 })
      ]),
      _: 3
    }, 8, ["onLoaded"])
  ]))
}