
import { ref, computed, defineComponent } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsContainer from '@web/components/AsContainer.vue'
import AsEmpty from '@web/components/AsEmpty.vue'

import Courses from '@web/components.v2/Home/All/Courses.vue'
import Events from '@web/components.v2/Home/All/Events.vue'
import Catalog from '@web/components.v2/Home/All/Catalog.vue'

import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { useRoute } from 'vue-router'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  name: 'HomeAll',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsFooter,
    AsSpacer,
    AsBreadcrumb,
    AsContainer,
    AsEmpty,

    Courses,
    Events,
    Catalog
  },
  setup () {
    const store = useStore()
    const route = computed(() => useRoute())
    const isOpenMenu = ref(false)
    const prevPage = { name: PAGE_NAME.HOME }

    const menu = computed(() => store.getters['menu/general'])

    const component = computed<string | null>(() => route.value?.name?.toString().split('-')[1] || null)

    const title = computed(() => {
      if (component.value === 'courses') {
        return i18n.global.t('myCourses')
      }
      if (component.value === 'events') {
        return i18n.global.t('dontMissIt')
      }
      if (component.value === 'catalog') {
        return i18n.global.t('courseCatalog')
      }

      return ''
    })

    return {
      prevPage,
      isOpenMenu,
      menu,
      title,
      component
    }
  }
})
