import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "all-courses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course = _resolveComponent("course")!
  const _component_courses = _resolveComponent("courses", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_courses, { onLoaded: _ctx.onLoaded }, {
      courses: _withCtx(({ courses }) => [
        (courses.length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(courses, (item) => {
              return (_openBlock(), _createBlock(_component_course, {
                key: item.stream.id,
                course: item.course,
                stream: item.stream,
                class: "all-courses_course",
                page: _ctx.page
              }, null, 8, ["course", "stream", "page"]))
            }), 128))
          : _renderSlot(_ctx.$slots, "empty", { key: 1 })
      ]),
      _: 3
    }, 8, ["onLoaded"])
  ]))
}