
import { defineComponent, ref } from 'vue'

import Item from '@web/components.v2/Home/Catalog/Item.vue'
import Items from '@web/components.v2/Home/Catalog/Items.vue'

export default defineComponent({
  name: 'AllEvents',
  components: {
    Item,
    Items
  },
  setup (props, ctx) {
    const loaded = ref(false)

    function onLoaded () {
      loaded.value = true
      ctx.emit('loaded', true)
    }

    function addBlock (number) {
      return !(number % 2 === 0)
    }

    return {
      loaded,
      onLoaded,
      addBlock
    }
  }
})
