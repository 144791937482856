import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lCommon home-all" }
const _hoisted_2 = { class: "home-all_content" }
const _hoisted_3 = { class: "home-all_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_container = _resolveComponent("as-container")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.title,
          "prev-page": _ctx.prevPage,
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
        }, null, 8, ["title", "prev-page"]),
        _withDirectives(_createVNode(_component_as_container, null, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), { page: "" }))
            ])
          ]),
          _: 1
        }, 512), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), { page: "" }))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}