
import { defineComponent, ref } from 'vue'

import Course from '@web/components.v2/Home/Courses/Course.vue'
import Courses from '@web/components.v2/Home/Courses/Courses.vue'

export default defineComponent({
  name: 'AllCourses',
  components: {
    Course,
    Courses
  },
  props: {
    page: {
      type: Boolean,
      default: false
    }
  },
  setup (props, ctx) {
    const loaded = ref(false)

    function onLoaded () {
      loaded.value = true
      ctx.emit('loaded', true)
    }

    return {
      loaded,
      onLoaded
    }
  }
})
