
import { defineComponent, ref } from 'vue'

import Event from '@web/components.v2/Home/Events/Event.vue'
import Events from '@web/components.v2/Home/Events/Events.vue'

export default defineComponent({
  name: 'AllEvents',
  components: {
    Event,
    Events
  },
  setup (props, ctx) {
    const loaded = ref(false)

    function onLoaded () {
      loaded.value = true
      ctx.emit('loaded', true)
    }

    function addBlock (number) {
      return !(number % 2 === 0)
    }

    return {
      loaded,
      onLoaded,
      addBlock
    }
  }
})
